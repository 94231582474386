<template lang="pug">
#login-container
	.logo-container
		img(src="../assets/img/lcap-logo-blue-png.png" alt="LCAP Logo")
	form.login-form
		.input
			p.input-label Email
			input(v-model="email", name="email", type="text", @keyup.enter="signIn")
		.input
			p.input-label Password
			input(
				v-model="password",
				name="password",
				type="password",
				@keyup.enter="signIn"
			)
		.warning(:class="{ show: warning != '' && warning != ' ' }") {{ warning }}
		.login-btn.btn.btn-primary(@click="signIn") Sign In
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import { auth, signInWithEmailAndPassword } from "@/firebase";
import { isEmail } from "@/util";

export default {
	name: "Home",
	components: {
		HelloWorld,
	},
	data() {
		return {
			email: "",
			password: "",
			warning: "",
		};
	},
	created() {
		auth.onAuthStateChanged((user) => {
			if (user) this.$router.push("/");
		});
	},
	methods: {
		async signIn() {
			if (this.email == "" || this.password == "")
				return (this.warning = "Please enter an email and password");

			if (!isEmail(this.email))
				return (this.warning = "Please enter a valid email address");

			try {
				await signInWithEmailAndPassword(auth, this.email, this.password);
				this.$router.push("/home");
			} catch (err) {
				switch (err.code) {
					case "auth/user-not-found":
						return (this.warning =
							"There is no user by this email. Please confirm there are no typos and try again");
					case "auth/wrong-password":
						return (this.warning = "Incorrect email or password");
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

#login-container {
	background-color: $theme-light;
	width: 100vw;
	min-height: 100vh;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.logo-container {
		margin-bottom: 48px;
	}
	.login-form {
		width: 100%;
		max-width: 320px;
		display: flex;
		flex-direction: column;
		gap: 36px;
	}
	.warning {
		text-align: center;
		color: $color-danger;
	}
	.login-btn {
		width: 160px;
		margin: 0 auto;
	}
}
</style>
