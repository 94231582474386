<template lang="pug">
#home
	img(
			src="../assets/img/lcap-logo-blue-png.png"
			alt="LCAP logo"
			class="lcap-logo"
		)
	h1.title Merced Union High School District
	.cards
		router-link.card(to="/")
			ViewIcon.icon
			p.text View
		router-link.card(to="/upload")
			UploadIcon.icon
			p.text Upload
		router-link.card(to="/edit")
			EditPageIcon.icon
			p.text Edit
		router-link.card(to="/manage")
			ManageIcon.icon
			p.text Manage
	button.log-out(@click="signOut") Log Out
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import ViewIcon from "@/components/icons/ViewIcon.vue";
import UploadIcon from "@/components/icons/UploadIcon.vue";
import EditPageIcon from "@/components/icons/EditPageIcon.vue";
import ManageIcon from "@/components/icons/ManageIcon.vue";
import { auth } from "@/firebase";

export default {
	name: "Home",
	components: {
		HelloWorld,
		ViewIcon,
		UploadIcon,
		EditPageIcon,
		ManageIcon,
	},
	created() {
		auth.onAuthStateChanged((user) => {
			if (!user) {
				this.$router.push("/login");
			}
		});
	},
	methods: {
		signOut() {
			auth.signOut();
			this.$router.push("/login");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/default.scss";

#home {
	width: 100vw;
	min-height: 100vh;
	padding: 20px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	* {
		flex-shrink: 0;
		min-height: 0;
	}

	.lcap-logo {
		margin-bottom: 48px;
		max-width: 100%;
		height: auto;
		margin-top: auto;
	}
	.title {
		text-align: center;
		margin-bottom: 48px;
	}

	.cards {
		// flex: 1;
		// height: 100%;
		display: grid;
		gap: 40px;
		margin-bottom: 48px;
		@include sm {
			grid-template-columns: repeat(2, 1fr);
		}
		@include lg {
			grid-template-columns: repeat(4, 1fr);
		}
		.card {
			background: #ffffff;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			min-width: 200px;
			min-height: 250px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition: box-shadow 0.5s ease;
			color: $color-blue;
			&:hover {
				box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.12);
			}
			.text {
				font-size: 20px;
				font-weight: 500;
			}
		}
	}

	.log-out {
		font-size: 14px;
		color: $color-lightblue;
		margin-top: auto;
		margin-bottom: 48px;
	}
}
</style>
